import { marked } from 'marked'
import renderMathInElement from 'katex/dist/contrib/auto-render'
import DOMPurify from 'dompurify'

/**
 * Applies styles to a table element.
 * @param {HTMLTableElement} table - The table to style.
 */
function applyTableStyles (table) {
  table.classList.add(
    'table', 'table-bordered', 'dataTable', 'no-footer',
  )
}

/**
 * Renders LaTeX in the given element.
 * @param {HTMLElement} element - The element to render LaTeX in.
 */
function renderLaTeX (element) {
  renderMathInElement(element, {
    delimiters: [
      { left: '$$', right: '$$', display: true },
      { left: '$', right: '$', display: false },
      { left: '\\(', right: '\\)', display: false },
      { left: '\\[', right: '\\]', display: true },
    ],
    output: 'mathml',
    throwOnError: false,
  })
}

/**
 * Cleans up reference links in the output.
 * @param {HTMLElement} output - The output element.
 */
function cleanupRefLinks (output) {
  output.querySelectorAll('a[href^="ref:"]').forEach((link) => {
    link.textContent = link.textContent.replace('ref:', '')
    link.removeAttribute('href')
  })
}

/**
 * Processes a thinking tag and creates a tooltip.
 * @param {HTMLElement} tag - The thinking tag to process.
 * @param {HTMLElement} output - The output element.
 */
function processThinkingTag (tag, output) {
  const ref = tag.getAttribute('ref')
  renderLaTeX(tag)
  const content = tag.innerHTML
  tag.style.display = 'none'

  const refLink = output.querySelector(`a[href="ref:${ref}"]`)
  if (refLink) {
    refLink.classList.add('thinking-result')
    refLink.style.cursor = 'pointer'

    const wrapper = document.createElement('span')
    wrapper.className = 'thinking-wrapper'
    refLink.parentNode.insertBefore(wrapper, refLink)
    wrapper.appendChild(refLink)

    wrapper.setAttribute('title', content)
    $(wrapper).bootstrapTooltip({
      html: true,
      template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner" style="max-width: 100%"></div></div>',
    })
  }
}

export const markdownRenderInElement = (markdownDom, markdownText) => {
  if (!markdownDom) return
  // EXAMPLE
  // DOMPurify.sanitize(
  //   '<thinking type="calculation" ref="calc-1">Some content here</thinking>' +
  //   '<p>This should remain.</p>' +
  //   '<a href="ref:calc-1">Reference Link</a>',
  //   {
  //     ADD_TAGS: ['thinking'],
  //     ADD_ATTR: ['type', 'ref'],
  //     ALLOW_UNKNOWN_PROTOCOLS: true,
  //   },
  // )
  // expect return:
  //   '<thinking ref="calc-1" type="calculation">Some content here</thinking>
  //   ' <p>This should remain.</p><a href="ref:calc-1">Reference Link</a>'

  markdownDom.innerHTML = DOMPurify.sanitize(
    marked.parse(markdownText),
    {
      ADD_TAGS: ['thinking', 'a'],
      ADD_ATTR: ['type', 'ref'],
      ALLOW_UNKNOWN_PROTOCOLS: true,
    },
  )

  markdownDom.querySelectorAll('table').forEach(applyTableStyles)
  markdownDom
    .querySelectorAll('thinking')
    .forEach((tag) => processThinkingTag(tag, markdownDom))
  cleanupRefLinks(markdownDom)
  renderLaTeX(markdownDom)
}
