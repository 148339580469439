const ResizeUtils = {
  addResizeListeners (resizableElementId) {
    const resizableElement = document.getElementById(resizableElementId)
    if (!resizableElement) {
      console.error(`Element with ID '${resizableElementId}' not found.`)
      return
    }
    let isResizing = false
    let canResize = false
    let initialWidth
    let initialClientX
    resizableElement.addEventListener('mousedown', mouseDownHandler)
    resizableElement.addEventListener('mouseup', mouseUpHandler)
    resizableElement.addEventListener('mousemove', mouseMoveHandler)
    resizableElement.addEventListener('mouseout', mouseOutHandler)

    function mouseDownHandler (e) {
      if (!canResize) return
      isResizing = true

      initialWidth = resizableElement.offsetWidth
      initialClientX = e.clientX
    }

    function mouseMoveHandler (e) {
      changeCursor(e)
      if (!isResizing) return
      let newWidth = initialWidth + (initialClientX - e.clientX)
      newWidth = Math.min(newWidth, 880)
      newWidth = Math.max(newWidth, 360)
      resizableElement.style.width = `${newWidth}px`
    }

    function mouseUpHandler () {
      isResizing = false
      document.removeEventListener('mousemove', mouseMoveHandler)
      document.removeEventListener('mouseup', mouseUpHandler)
    }

    function changeCursor (e) {
      if (isResizing) return
      const rect = e.target.getBoundingClientRect()
      if (e.clientX - rect.left < 10) {
        document.body.style.cursor = 'ew-resize'
        canResize = true
      } else {
        document.body.style.cursor = 'default'
        canResize = false
      }
    }

    function mouseOutHandler () {
      if (isResizing) {
        document.addEventListener('mousemove', mouseMoveHandler)
        document.addEventListener('mouseup', mouseUpHandler)
        return
      }
      document.body.style.cursor = 'default'
      canResize = false
      isResizing = false
    }
  },
}
export default ResizeUtils
